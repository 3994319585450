export const AuthActionTypes = {
   LOGIN: 'LOGIN',
   LOGIN_PENDING: 'LOGIN_PENDING',
   LOGIN_FULFILLED: 'LOGIN_FULFILLED',

   SIGNUP: 'SIGNUP',
   SIGNUP_PENDING: 'SIGNUP_PENDING',
   SIGNUP_FULFILLED: 'SIGNUP_FULFILLED',

   GOOGLE_AUTH: 'GOOGLE_AUTH',
   GOOGLE_AUTH_PENDING: 'GOOGLE_AUTH_PENDING',
   GOOGLE_AUTH_FULFILLED: 'GOOGLE_AUTH_FULFILLED',

   FB_AUTH: 'FB_AUTH',
   FB_AUTH_PENDING: 'FB_AUTH_PENDING',
   FB_AUTH_FULFILLED: 'FB_AUTH_FULFILLED',

   VERIFY_OTP: 'VERIFY_OTP',
   VERIFY_OTP_PENDING: 'VERIFY_OTP_PENDING',
   VERIFY_OTP_FULFILLED: 'VERIFY_OTP_FULFILLED',

   FETCH_CURRENT_USER: 'FETCH_CURRENT_USER',
   FETCH_CURRENT_USER_PENDING: 'FETCH_CURRENT_USER_PENDING',
   FETCH_CURRENT_USER_FULFILLED: 'FETCH_CURRENT_USER_FULFILLED',

   RESEND_OTP: 'RESEND_OTP',
   RESEND_OTP_PENDING: 'RESEND_OTP_PENDING',
   RESEND_OTP_FULFILLED: 'RESEND_OTP_FULFILLED',

   FORGOT_PASSWORD: 'FORGOT_PASSWORD',
   FORGOT_PASSWORD_PENDING: 'FORGOT_PASSWORD_PENDING',
   FORGOT_PASSWORD_FULFILLED: 'FORGOT_PASSWORD_FULFILLED',

   RESET_PASSWORD: 'RESET_PASSWORD',
   RESET_PASSWORD_PENDING: 'RESET_PASSWORD_PENDING',
   RESET_PASSWORD_FULFILLED: 'RESET_PASSWORD_FULFILLED',

   SIGNOUT: 'SIGNOUT',
   SIGNOUT_PENDING: 'SIGNOUT_PENDING',
   SIGNOUT_FULFILLED: 'SIGNOUT_FULFILLED',

   AUTHORIZE: 'AUTHORIZE',
   LOGOUT: 'LOGOUT',
   SEND_OTP: 'SEND_OTP',
   IS_SIGNING_IN_FROM_DETAILS: 'IS_SIGNING_IN_FROM_DETAILS',
   TOGGLE_TERMS_AGREED_FROM_MODAL: 'TOGGLE_TERMS_AGREED_FROM_MODAL',

   UPDATE_USER: 'UPDATE_USER',
   UPDATE_USER_PENDING: 'UPDATE_USER_PENDING',
   UPDATE_USER_FULFILLED: 'UPDATE_USER_FULFILLED',

   UPDATE_ACCOUNT_INFO: 'UPDATE_ACCOUNT_INFO',
   UPDATE_ACCOUNT_INFO_PENDING: 'UPDATE_ACCOUNT_INFO_PENDING',
   UPDATE_ACCOUNT_INFO_FULFILLED: 'UPDATE_ACCOUNT_INFO_FULFILLED',

   CHANGE_PASSWORD: 'CHANGE_PASSWORD',
   CHANGE_PASSWORD_PENDING: 'CHANGE_PASSWORD_PENDING',
   CHANGE_PASSWORD_FULFILLED: 'CHANGE_PASSWORD_FULFILLED',

   VERIFY_EDU_EMAIL: 'VERIFY_EDU_EMAIL',
   VERIFY_EDU_EMAIL_PENDING: 'VERIFY_EDU_EMAIL_PENDING',
   VERIFY_EDU_EMAIL_FULFILLED: 'VERIFY_EDU_EMAIL_FULFILLED',

   SEND_EDU_EMAIL: 'SEND_EDU_EMAIL',
   SEND_EDU_EMAIL_PENDING: 'SEND_EDU_EMAIL_PENDING',
   SEND_EDU_EMAIL_FULFILLED: 'SEND_EDU_EMAIL_FULFILLED',

   REFRESH_AUTH_TOKEN: 'REFRESH_AUTH_TOKEN',
   REFRESH_AUTH_TOKEN_PENDING: 'REFRESH_AUTH_TOKEN_PENDING',
   REFRESH_AUTH_TOKEN_FULFILLED: 'REFRESH_AUTH_TOKEN_FULFILLED',

   UPDATE_QUICKSTART_ITEMS: 'UPDATE_QUICKSTART_ITEMS'
};

export const ProfileActionTypes = {
   PROFILE_PHOTO_UPLOAD: 'PROFILE_PHOTO_UPLOAD',
   PROFILE_PHOTO_UPLOAD_PENDING: 'PROFILE_PHOTO_UPLOAD_PENDING',
   PROFILE_PHOTO_UPLOAD_FULFILLED: 'PROFILE_PHOTO_UPLOAD_FULFILLED',

   CREATE_NEW_PROFILE: 'CREATE_NEW_PROFILE',
   CREATE_NEW_PROFILE_PENDING: 'CREATE_NEW_PROFILE_PENDING',
   CREATE_NEW_PROFILE_FULFILLED: 'CREATE_NEW_PROFILE_FULFILLED',

   TOGGLE_PROFILE: 'TOGGLE_PROFILE',
   TOGGLE_PROFILE_PENDING: 'TOGGLE_PROFILE_PENDING',
   TOGGLE_PROFILE_FULFILLED: 'TOGGLE_PROFILE_FULFILLED',

   CREATE_NEW_EXPERIENCE: 'CREATE_NEW_EXPERIENCE',
   CREATE_NEW_EXPERIENCE_PENDING: 'CREATE_NEW_EXPERIENCE_PENDING',
   CREATE_NEW_EXPERIENCE_FULFILLED: 'CREATE_NEW_EXPERIENCE_FULFILLED',

   CREATE_NEW_PORTFOLIO: 'CREATE_NEW_PORTFOLIO',
   CREATE_NEW_PORTFOLIO_PENDING: 'CREATE_NEW_PORTFOLIO_PENDING',
   CREATE_NEW_PORTFOLIO_FULFILLED: 'CREATE_NEW_PORTFOLIO_FULFILLED',

   CREATE_NEW_SKILL: 'CREATE_NEW_SKILL',
   CREATE_NEW_SKILL_PENDING: 'CREATE_NEW_SKILL_PENDING',
   CREATE_NEW_SKILL_FULFILLED: 'CREATE_NEW_SKILL_FULFILLED',

   UPLOAD_PROFILE_PHOTO_TO_S3BUCKET: 'UPLOAD_PROFILE_PHOTO_TO_S3BUCKET',
   UPLOAD_PROFILE_PHOTO_TO_S3BUCKET_PENDING:
      'UPLOAD_PROFILE_PHOTO_TO_S3BUCKET_PENDING',
   UPLOAD_PROFILE_PHOTO_TO_S3BUCKET_FULFILLED:
      'UPLOAD_PROFILE_PHOTO_TO_S3BUCKET_FULFILLED',

   UPDATE_PROFILE: 'UPDATE_PROFILE',
   UPDATE_PROFILE_PENDING: 'UPDATE_PROFILE_PENDING',
   UPDATE_PROFILE_FULFILLED: 'UPDATE_PROFILE_FULFILLED',

   DELETE_PROFILE: 'DELETE_PROFILE',
   DELETE_PROFILE_PENDING: 'DELETE_PROFILE_PENDING',
   DELETE_PROFILE_FULFILLED: 'DELETE_PROFILE_FULFILLED',

   UPDATE_EXPERIENCE: 'UPDATE_EXPERIENCE',
   UPDATE_EXPERIENCE_PENDING: 'UPDATE_EXPERIENCE_PENDING',
   UPDATE_EXPERIENCE_FULFILLED: 'UPDATE_EXPERIENCE_FULFILLED',

   UPDATE_SKILLS: 'UPDATE_SKILLS',
   UPDATE_SKILLS_PENDING: 'UPDATE_SKILLS_PENDING',
   UPDATE_SKILLS_FULFILLED: 'UPDATE_SKILLS_FULFILLED',

   UPDATE_PORTFOLIO: 'UPDATE_PORTFOLIO',
   UPDATE_PORTFOLIO_PENDING: 'UPDATE_PORTFOLIO_PENDING',
   UPDATE_PORTFOLIO_FULFILLED: 'UPDATE_PORTFOLIO_FULFILLED',

   DELETE_EXPERIENCE: 'DELETE_EXPERIENCE',
   DELETE_EXPERIENCE_PENDING: 'DELETE_EXPERIENCE_PENDING',
   DELETE_EXPERIENCE_FULFILLED: 'DELETE_EXPERIENCE_FULFILLED',

   DELETE_PORTFOLIO: 'DELETE_PORTFOLIO',
   DELETE_PORTFOLIO_PENDING: 'DELETE_PORTFOLIO_PENDING',
   DELETE_PORTFOLIO_FULFILLED: 'DELETE_PORTFOLIO_FULFILLED',

   PERSONALPROFILE: 'PERSONALPROFILE',
   PERSONALPROFILE_PENDING: 'PERSONALPROFILE_PENDING',
   PERSONALPROFILE_FULFILLED: 'PERSONALPROFILE_FULFILLED',

   BUSINESSPROFILE: 'BUSINESSPROFILE',
   BUSINESSPROFILE_PENDING: 'BUSINESSPROFILE_PENDING',
   BUSINESSPROFILE_FULFILLED: 'BUSINESSPROFILE_FULFILLED',

   FETCHBUSINESSPROFILE: 'FETCHBUSINESSPROFILE',
   FETCHBUSINESSPROFILE_PENDING: 'FETCHBUSINESSPROFILE_PENDING',
   FETCHBUSINESSPROFILE_FULFILLED: 'FETCHBUSINESSPROFILE_FULFILLED',

   GET_PROFILE: 'GET_PROFILE',
   GET_PROFILE_PENDING: 'GET_PROFILE_PENDING',
   GET_PROFILE_FULFILLED: 'GET_PROFILE_FULFILLED',

   UPLOAD_PROFILE_PHOTO_TO_WORDPRESS: 'UPLOAD_PROFILE_PHOTO_TO_WORDPRESS',
   UPLOAD_PROFILE_PHOTO_TO_WORDPRESS_PENDING: 'UPLOAD_PROFILE_PHOTO_TO_WORDPRESS_PENDING',
   UPLOAD_PROFILE_PHOTO_TO_WORDPRESS_FULFILLED: 'UPLOAD_PROFILE_PHOTO_TO_WORDPRESS_FULFILLED',
};

export const ConfigActionTypes = {
   PULL_CONFIG: 'PULL_CONFIG',
   PULL_CONFIG_PENDING: 'PULL_CONFIG_PENDING',
   PULL_CONFIG_FULFILLED: 'PULL_CONFIG_FULFILLED',

   PULL_SETTINGS: 'PULL_SETTINGS',
   PULL_SETTINGS_PENDING: 'PULL_SETTINGS_PENDING',
   PULL_SETTINGS_FULFILLED: 'PULL_SETTINGS_FULFILLED',

   PULL_CITY_LIST: 'PULL_CITY_LIST',
   PULL_CITY_LIST_PENDING: 'PULL_CITY_LIST_PENDING',
   PULL_CITY_LIST_FULFILLED: 'PULL_CITY_LIST_FULFILLED',

   PULL_SUCCESS_STORIES: 'PULL_SUCCESS_STORIES',
   PULL_SUCCESS_STORIES_PENDING: 'PULL_SUCCESS_STORIES_PENDING',
   PULL_SUCCESS_STORIES_FULFILLED: 'PULL_SUCCESS_STORIES_FULFILLED',

   TOGGLE_FOOTER: 'TOGGLE_FOOTER',
};

export const HistoryActionTypes = {
   ADD_HISTORY: 'ADD_HISTORY',
};

export const OffersActionsTypes = {
   SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY"
}

export const MiscActionTypes = {
   SEARCH: 'SEARCH',
   SEARCH_PENDING: 'SEARCH_PENDING',
   SEARCH_FULFILLED: 'SEARCH_FULFILLED',

   LOAD_MORE: 'LOAD_MORE',
   LOAD_MORE_PENDING: 'LOAD_MORE_PENDING',
   LOAD_MORE_FULFILLED: 'LOAD_MORE_FULFILLED',

   FEATURED_TALENT: 'FEATURED_TALENT',
   FEATURED_TALENT_PENDING: 'FEATURED_TALENT_PENDING',
   FEATURED_TALENT_FULFILLED: 'FEATURED_TALENT_FULFILLED',

   FETCH_FAQ: 'FETCH_FAQ',
   FETCH_FAQ_PENDING: 'FETCH_FAQ_PENDING',
   FETCH_FAQ_FULFILLED: 'FETCH_FAQ_FULFILLED',

   SHUFFLE_SEARCH_RESULTS: 'SHUFFLE_SEARCH_RESULTS',

   SHARE_URL: 'SHARE_URL',
   IS_SHARING_VIA_MSG: 'IS_SHARING_VIA_MSG',

   STORE_USER_SEARCH: 'STORE_USER_SEARCH',

   STORE_FULL_DESCRIPTION: 'STORE_FULL_DESCRIPTION',

   SHOW_IMAGE_CROP_MODAL: 'SHOW_IMAGE_CROP_MODAL'
};

export const UserActionType = {
   GET_FEATURED_PROFILE: 'GET_FEATURED_PROFILE',
   GET_FEATURED_PROFILE_PENDING: 'GET_FEATURED_PROFILE_PENDING',
   GET_FEATURED_PROFILE_FULFILLED: 'GET_FEATURED_PROFILE_FULFILLED',

   ADD_SERVICE: 'ADD_SERVICE',
   ADD_SERVICE_PENDING: 'ADD_SERVICE_PENDING',
   ADD_SERVICE_FULFILLED: 'ADD_SERVICE_FULFILLED',

   GET_PROFILE_BY_ID: 'GET_PROFILE_BY_ID',
   GET_PROFILE_BY_ID_PENDING: 'GET_PROFILE_BY_ID_PENDING',
   GET_PROFILE_BY_ID_FULFILLED: 'GET_PROFILE_BY_ID_FULFILLED',

   GET_SERVICE_LIST: 'GET_SERVICE_LIST',
   GET_SERVICE_LIST_PENDING: 'GET_SERVICE_LIST_PENDING',
   GET_SERVICE_LIST_FULFILLED: 'GET_SERVICE_LIST_FULFILLED',

   GET_OFFERS_BY_ID: 'GET_OFFERS_BY_ID',
   GET_OFFERS_BY_ID_PENDING: 'GET_OFFERS_BY_ID_PENDING',
   GET_OFFERS_BY_ID_FULFILLED: 'GET_OFFERS_BY_ID_FULFILLED',

   UPDATE_SERVICE: 'UPDATE_SERVICE',
   UPDATE_SERVICE_PENDING: 'UPDATE_SERVICE_PENDING',
   UPDATE_SERVICE_FULFILLED: 'UPDATE_SERVICE_FULFILLED',

   DELETE_SERVICE: 'DELETE_SERVICE',
   DELETE_SERVICE_PENDING: 'DELETE_SERVICE_PENDING',
   DELETE_SERVICE_FULFILLED: 'DELETE_SERVICE_FULFILLED',

   UPDATE_TOGGLE_SERVICE: 'UPDATE_TOGGLE_SERVICE',
   TOGGLE_ACTIVE_INACTIVE_SERVICE: 'TOGGLE_ACTIVE_INACTIVE_SERVICE',
   TOGGLE_ACTIVE_INACTIVE_SERVICE_PENDING:
      'TOGGLE_ACTIVE_INACTIVE_SERVICE_PENDING',
   TOGGLE_ACTIVE_INACTIVE_SERVICE_FULFILLED:
      'TOGGLE_ACTIVE_INACTIVE_SERVICE_FULFILLED',

   GET_PRESIGNED_URL: 'GET_PRESIGNED_URL',
   GET_PRESIGNED_URL_PENDING: 'GET_PRESIGNED_URL_PENDING',
   GET_PRESIGNED_URL_FULFILLED: 'GET_PRESIGNED_URL_FULFILLED',

   GET_SERVICE_PRESIGNED_URL: 'GET_SERVICE_PRESIGNED_URL',
   GET_SERVICE_PRESIGNED_URL_PENDING: 'GET_SERVICE_PRESIGNED_URL_PENDING',
   GET_SERVICE_PRESIGNED_URL_FULFILLED: 'GET_SERVICE_PRESIGNED_URL_FULFILLED',

   GET_CLASSIFIED_PRESIGNED_URL: 'GET_CLASSIFIED_PRESIGNED_URL',
   GET_CLASSIFIED_PRESIGNED_URL_PENDING: 'GET_CLASSIFIED_PRESIGNED_URL_PENDING',
   GET_CLASSIFIED_PRESIGNED_URL_FULFILLED:
      'GET_CLASSIFIED_PRESIGNED_URL_FULFILLED',

   UPLOAD_IMAGE_TO_S3: 'UPLOAD_IMAGE_TO_S3',
   UPLOAD_IMAGE_TO_S3_PENDING: 'UPLOAD_IMAGE_TO_S3_PENDING',
   UPLOAD_IMAGE_TO_S3_FULFILLED: 'UPLOAD_IMAGE_TO_S3_FULFILLED',

   ADD_PORTFOLIO_IMAGES: 'ADD_PORTFOLIO_IMAGES',
   ADD_PORTFOLIO_IMAGES_PENDING: 'ADD_PORTFOLIO_IMAGES_PENDING',
   ADD_PORTFOLIO_IMAGES_FULFILLED: 'ADD_PORTFOLIO_IMAGES_FULFILLED',

   ADD_DECK_CLICKED: 'ADD_DECK_CLICKED',
   GET_DECK_LIST: 'GET_DECK_LIST',
   GET_DECK_LIST_PENDING: 'GET_DECK_LIST_PENDING',
   GET_DECK_LIST_FULFILLED: 'GET_DECK_LIST_FULFILLED',

   CREATE_DECK: 'CREATE_DECK',
   CREATE_DECK_PENDING: 'CREATE_DECK_PENDING',
   CREATE_DECK_FULFILLED: 'CREATE_DECK_FULFILLED',

   ADD_SERVICE_TO_DECK: 'ADD_SERVICE_TO_DECK',
   ADD_SERVICE_TO_DECK_PENDING: 'ADD_SERVICE_TO_DECK_PENDING',
   ADD_SERVICE_TO_DECK_FULFILLED: 'ADD_SERVICE_TO_DECK_FULFILLED',

   DELETE_DECK: 'DELETE_DECK',
   DELETE_DECK_PENDING: 'DELETE_DECK_PENDING',
   DELETE_DECK_FULFILLED: 'DELETE_DECK_FULFILLED',

   FILTER_DECK_CLICKED: 'FILTER_DECK_CLICKED',

   GET_CLASSIFIED_LIST: 'GET_CLASSIFIED_LIST',
   GET_CLASSIFIED_LIST_PENDING: 'GET_CLASSIFIED_LIST_PENDING',
   GET_CLASSIFIED_LIST_FULFILLED: 'GET_CLASSIFIED_LIST_FULFILLED',

   UPDATE_CONVERSATION_RECEIVER: 'UPDATE_CONVERSATION_RECEIVER',
   UPDATE_SELECTED_CHAT_CHANNEL: 'UPDATE_SELECTED_CHAT_CHANNEL',

   ADD_CLASSIFIED: 'ADD_CLASSIFIED',
   ADD_CLASSIFIED_PENDING: 'ADD_CLASSIFIED_PENDING',
   ADD_CLASSIFIED_FULFILLED: 'ADD_CLASSIFIED_FULFILLED',

   UPDATE_CLASSIFIED: 'UPDATE_CLASSIFIED',
   UPDATE_CLASSIFIED_PENDING: 'UPDATE_CLASSIFIED_PENDING',
   UPDATE_CLASSIFIED_FULFILLED: 'UPDATE_CLASSIFIED_FULFILLED',

   DELETE_CLASSIFIED: 'DELETE_CLASSIFIED',
   DELETE_CLASSIFIED_PENDING: 'DELETE_CLASSIFIED_PENDING',
   DELETE_CLASSIFIED_FULFILLED: 'DELETE_CLASSIFIED_FULFILLED',

   NAVIGATE_TO_DETAILS: 'NAVIGATE_TO_DETAILS',

   STORE_SERVICE_DETAILS: 'STORE_SERVICE_DETAILS',

   FETCH_REPORTED_USERS: 'FETCH_REPORTED_USERS',
   FETCH_REPORTED_USERS_PENDING: 'FETCH_REPORTED_USERS_PENDING',
   FETCH_REPORTED_USERS_FULFILLED: 'FETCH_REPORTED_USERS_FULFILLED',

   UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
   UPDATE_USER_SETTINGS_PENDING: 'UPDATE_USER_SETTINGS_PENDING',
   UPDATE_USER_SETTINGS_FULFILLED: 'UPDATE_USER_SETTINGS_FULFILLED',

   SEARCH_USER_BY_NAME: 'SEARCH_USER_BY_NAME',
   SEARCH_USER_BY_NAME_PENDING: 'SEARCH_USER_BY_NAME_PENDING',
   SEARCH_USER_BY_NAME_FULFILLED: 'SEARCH_USER_BY_NAME_FULFILLED',

   SEARCH_USER_BY_NAME_EMPTY: 'SEARCH_USER_BY_NAME_EMPTY',

   CREATE_CLASSIFIED_CLICKED: 'CREATE_CLASSIFIED_CLICKED',
   MANAGE_CLASSIFIED_CLICKED: 'MANAGE_CLASSIFIED_CLICKED',

   DELETE_PARTICULAR_OFFER_FROM_DECKS: 'DELETE_PARTICULAR_OFFER_FROM_DECKS',
   DELETE_PARTICULAR_OFFER_FROM_DECKS_PENDING:
      'DELETE_PARTICULAR_OFFER_FROM_DECKS_PENDING',
   DELETE_PARTICULAR_OFFER_FROM_DECKS_FULFILLED:
      'DELETE_PARTICULAR_OFFER_FROM_DECKS_FULFILLED',

};
export const WalkthorughActionType = {
   WALKTHROUGH_START: 'WALKTHROUGH_START',
   // WALKTHROUGH_COMPLETE:'WALKTHROUGH_COMPLETE'
}